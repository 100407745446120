import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";

export default {
  fillable_by: FillableBy.Evaluator,
  title:
    "Employee Competency Assessment",
  sections: [
    // {...employeeinfo},
    {
      title: "Clinical Competence",
      type: "fields",
      fields: [
        {
          label: "Is the perfusionist clinically competent in your assessment?",
          fillable_by: FillableBy.Evaluator,
          type: "a-radio",
          span: 24,
          value: "",
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
        {
          fillable_by: FillableBy.Evaluator,
          label: "Comments or Observations Regarding Clinical Competence",
          placeholder: "",
          type: "a-textarea",
          span: 24,
          rows: 10,
          value: "",
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
      ],
    },
    {
      title: "Adherence to Protocols",
      type: "fields",
      fields: [
        {
          label: "Does the perfusionist consistently adhere to standard protocols and guidelines during procedures?",
          fillable_by: FillableBy.Evaluator,
          type: "a-radio",
          span: 24,
          value: "",
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
        {
          fillable_by: FillableBy.Evaluator,
          label: "Comments or Observations Regarding Adherence to Protocols",
          placeholder: "",
          type: "a-textarea",
          span: 24,
          rows: 10,
          value: "",
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
      ],
    },
    {
      title: "Communication and Teamwork",
      type: "fields",
      fields: [
        {
          label: "Does the perfusionist communicate effectively and work well within the surgical team and hospital staff?",
          fillable_by: FillableBy.Evaluator,
          type: "a-radio",
          span: 24,
          value: "",
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
        {
          fillable_by: FillableBy.Evaluator,
          label: "Comments or Observations Regarding Communication and Teamwork",
          placeholder: "",
          type: "a-textarea",
          span: 24,
          rows: 10,
          value: "",
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
      ],
    },
    {
      title: "Problem Solving and Decision-Making",
      type: "fields",
      fields: [
        {
          label: "Has the perfusionist demonstrated effective problem-solving skills and quick decision-making during procedures?",
          fillable_by: FillableBy.Evaluator,
          type: "a-radio",
          span: 24,
          value: "",
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
        {
          fillable_by: FillableBy.Evaluator,
          label: "Comments or Observations Regarding Problem Solving and Decision-Making",
          placeholder: "",
          type: "a-textarea",
          span: 24,
          rows: 10,
          value: "",
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
      ],
    },
    {
      title: "Overall Assessment",
      type: "fields",
      fields: [
        {
          label: "Based on your experience working with this perfusionist, would you welcome them to return to your facility for future assignments?",
          fillable_by: FillableBy.Evaluator,
          type: "a-radio",
          span: 24,
          value: "",
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
        {
          fillable_by: FillableBy.Evaluator,
          label: "Comments or Observations Regarding Overall Assessment",
          placeholder: "",
          type: "a-textarea",
          span: 24,
          rows: 10,
          value: "",
          rules: {
            required: true,
            message: "This field is required.",
          },
        },
      ],
    },
    {
      title: "Signatures",
      type: "signature",
      fields:getSignatures(FillableBy.Evaluator)
    },
  ]
}